import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SEO, Header, Layout, PreviewCompatibleImage } from "../components";
import { CardWithButton, CardContainer } from "../ui";
import { SectionTitle } from "../ui/Texts";
import { Wrapper, Container } from "../ui/LayoutComponents";
import { linearGradients } from "../ui/variables";

import bgImage from "../img/header-images/scientifique-microscope.jpg";

export const RecherchePageTemplate = ({ title, posts }) => {
  return (
    <>
      <SEO title={title} />
      <Header title={title} bgImage={bgImage} bgPosition="center 75%" fallback={linearGradients.recherche} />
      <Wrapper>
        <Container large>
          <SectionTitle marginB="3rem">Interviews</SectionTitle>
          <CardContainer two>
            {posts.map(({ node: post }) => {
              const { id, frontmatter, fields } = post;
              const { title, intro, thumb, authors } = frontmatter;
              const abstract = intro ? intro : authors[0].description;
              const truncatedText = abstract.slice(0, 225);
              const cardText = truncatedText.concat("", "...");
              return (
                <CardWithButton key={id} title={title} text={cardText} link={fields.slug}>
                  <PreviewCompatibleImage
                    imageInfo={thumb}
                    style={{ height: "100%", borderRadius: "4px 4px 0 0" }}
                  />
                </CardWithButton>
              );
            })}
          </CardContainer>
        </Container>
      </Wrapper>
    </>
  );
};

RecherchePageTemplate.propTypes = {
  title: PropTypes.string,
  posts: PropTypes.array,
};

const RecherchePage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <RecherchePageTemplate title={frontmatter.title} posts={posts} />
    </Layout>
  );
};

RecherchePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
    markdownRemark: PropTypes.object,
  }),
};

export default RecherchePage;

export const pageQuery = graphql`
  query RecherchePage($id: String!) {
    allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "interview-post" } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            intro
            thumb {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            authors {
              description
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
    }
  }
`;
